<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <validation-observer ref="simpleRules">
      <div>
        <b-card>
          <div
            class="d-lg-flex justify-content-between align-items-center mt-2"
          >
            <h3 class="font-weight-bolder">
              {{ $t("ledger") }}
            </h3>

            <div class="d-flex">
              <div class="income-order-search order-search w-100">
                <div class="anan-input search-input">
                  <div class="anan-input__inner anan-input__inner--normal">
                    <input
                      v-model="search"
                      type="text"
                      :placeholder="$t('key-67')"
                      class="anan-input__input"
                      @change="getData()"
                    >
                    <div class="anan-input__suffix">
                      <i class="anan-input__clear-btn anan-icon" />
                      <i class="anan-input__suffix-icon anan-icon">
                        <i class="fal fa-search" />
                      </i>
                    </div>
                  </div>
                </div>
              </div>

              <b-form-select
                v-model="isActive"
                :options="options"
                class="w-50 ml-1"
                size="sm"
                @change="getData()"
              />
            </div>
          </div>

          <button
            class="anan-button--xl-large anan-button anan-button--primary anan-button--normal mt-1"
            @click="showAddNew()"
          >
            <span> {{ $t("addNewItem") }} </span>
          </button>
          <b-col cols="12">
            <b-table
              striped
              hover
              responsive
              :per-page="perPage"
              :items="items"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              :filter="filter"
              :filter-included-fields="filterOn"
            >
              <template #cell(number)="data">
                <b-link :to="{}">
                  {{ data.item.itemnumber_text }}
                </b-link>
              </template>
              <template #cell(amount)="data">
                <!-- {{ Commas(data.item.amount) }} -->
                <!-- <span
                  v-if="data.item.amount < 0"
                  class="text-danger"
                >
                  {{ Commas(data.item.amount) }} ฿
                </span> -->
                <span
                  :style="getAmountStyle(data.item.type, data.item.type_amount)"
                >
                  {{ getAmountPrefix(data.item.type, data.item.type_amount) }}
                  {{ Commas(data.item.amount) }} ฿
                </span>
              </template>
              <template #cell(detail)="data">
                {{ data.item.detail }}
                <span v-if="data.item.type === 2 && data.item.file_slip">
                  <img
                    :src="GetImg('Filewithdraw', data.item.file_slip)"
                    alt="Preview"
                    class="text-center"
                    width="35"
                    @click="showPicture(data.item.file_slip)"
                  >

                  <vue-image-lightbox-carousel
                    ref="lightbox"
                    :show="showLightbox"
                    :images="images"
                    :show-caption="false"
                    @close="closeBox()"
                  />
                </span>
              </template>
              <template #cell(actions)="data">
                <feather-icon
                  icon="Edit3Icon"
                  class="mr-1 text-primary cursor-pointer"
                  @click="showEdit(data.item)"
                />
              </template>
            </b-table>
          </b-col>
          <b-col cols="12">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-1 mx-1"
              @input="getData()"
            />
          </b-col>
        </b-card>

        <b-modal
          id="modal-add-new"
          :title="$t('key-21')"
          :ok-title="$t('save')"
          :cancel-title="$t('cancel')"
          @ok.prevent="submit()"
        >
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Customer Code"
              rules="required"
            >
              <label
                for="customerCode"
              >{{ $t("customerCode") }} :
                <span class="text-danger">*</span></label>
              <v-select
                v-model="username_cus"
                label="username"
                :options="cuslist"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Type"
              rules="required"
            >
              <label
                for="type"
              >{{ $t("type") }} : <span class="text-danger">*</span></label>
              <b-form-select
                v-model="type"
                :options="typeOp"
                :state="errors.length > 0 ? false : null"
              />
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="detail">{{ $t("details") }} :</label>
            <b-form-textarea
              id="detail"
              v-model="detail"
              :placeholder="$t('details')"
            />
          </b-form-group>

          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Amount"
              rules="required"
            >
              <label
                for="amount"
              >{{ $t("quantity") }} :
                <span class="text-danger">*</span></label>
              <b-input-group>
                <b-form-input
                  id="amount"
                  v-model="amount"
                  type="number"
                  :state="errors.length > 0 ? false : null"
                  :placeholder="$t('quantity')"
                  :formatter="numberFormat"
                />
                <!-- oninput="this.value = this.value.replace(/[^0-9]/g, '');" -->
                <b-input-group-append v-if="type == 21">
                  <b-button
                    :class="{ 'active-btn': natural_num }"
                    variant="outline-secondary"
                    @click="natural_num = true"
                  >+</b-button>
                  <b-button
                    :class="{ 'active-btn': !natural_num}"
                    variant="outline-secondary"
                    @click="natural_num = false"
                  >-</b-button>
                </b-input-group-append>
              </b-input-group>
              <span class="text-danger">{{ errors[0] }}</span>
            </validation-provider>
          </b-form-group>

          <b-form-group>
            <label for="prefix">Prefix :</label>
            <b-form-input
              id="prefix"
              v-model="prefix"
              type="text"
              placeholder="Prefix"
            />
          </b-form-group>

          <b-form-group>
            <label for="prefixNum">Prefix Number :</label>
            <b-form-input
              id="prefixNum"
              v-model="prefixnumber"
              type="number"
              placeholder="Prefix Number"
            />
          </b-form-group>
        </b-modal>
      </div>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BTable,
  BCard,
  BLink,
  BFormSelect,
  BModal,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay,
  // BIconController,
  BPagination,
  BInputGroup,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import VueImageLightboxCarousel from 'vue-image-lightbox-carousel'

export default {
  components: {
    BCard,
    BTable,
    BLink,
    BFormSelect,
    BModal,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    // BIconController,
    BPagination,
    vSelect,
    VueImageLightboxCarousel,
    BInputGroup,
    BInputGroupAppend,
    BButton,
  },
  data() {
    return {
      required,
      selected: null,
      id: null,
      amount: 0,
      detail: '',
      type: null,
      username_cus: '',
      prefix: '',
      prefixnumber: '',
      show: false,

      currentPage: 1,
      perPage: 10,
      search: null,
      isActive: 'all',
      items: [],
      showLightbox: false,
      images: [],
      itemnumber_text: null,
      natural_num: true,
    }
  },
  computed: {
    options() {
      return [
        { value: 'all', text: this.$t('all') },
        { value: 1, text: this.$t('topUp') },
        { value: 2, text: this.$t('withdraw') },
        { value: 3, text: this.$t('key-287') },
        { value: 4, text: this.$t('key-179') },
        { value: 5, text: this.$t('key-180') },
        { value: 6, text: this.$t('mall') },
        { value: 7, text: this.$t('DepositTaobao') },
        { value: 8, text: this.$t('commissionFee') },
        { value: 9, text: this.$t('topupAlipay') },
        { value: 10, text: this.$t('transferToChina') },
        { value: 11, text: this.$t('reportMissing') },
        { value: 12, text: this.$t('key-288') },
        { value: 13, text: this.$t('domesticDeliveryFee') },
        { value: 14, text: this.$t('key-289') },
        { value: 15, text: this.$t('sendToChina') },
        { value: 16, text: this.$t('redeemPoints') },
        { value: 17, text: this.$t('Refunds') },
        { value: 20, text: 'ยกเลิก Commission' },
        { value: 22, text: 'ค่าคูปอง' },
        { value: 21, text: this.$t('other') },
      ]
    },

    typeOp() {
      return [
        { value: 1, text: this.$t('topUp') },
        { value: 2, text: this.$t('withdraw') },
        { value: 3, text: this.$t('key-287') },
        { value: 4, text: this.$t('key-179') },
        { value: 5, text: this.$t('key-180') },
        { value: 6, text: this.$t('mall') },
        { value: 7, text: this.$t('DepositTaobao') },
        // { value: 8, text: 'ค่า Commission' },
        { value: 9, text: this.$t('topupAlipay') },
        { value: 10, text: this.$t('transferToChina') },
        { value: 11, text: this.$t('reportMissing') },
        { value: 12, text: this.$t('key-288') },
        { value: 13, text: this.$t('domesticDeliveryFee') },
        { value: 14, text: this.$t('key-289') },
        { value: 15, text: this.$t('sendToChina') },
        { value: 16, text: this.$t('redeemPoints') },
        { value: 17, text: this.$t('Refunds') },
        { value: 22, text: 'ค่าคูปอง' },
        { value: 21, text: this.$t('other') },
      ]
    },
    fields() {
      return [
        {
          key: 'number',
          label: this.$t('orderNumber'),
          thStyle: { width: '10%' },
          thClass: 'text-center',
        },
        {
          key: 'created_at',
          label: this.$t('transactionDate'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },
        {
          key: 'username_cus',
          label: this.$t('customerCode'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'add_by',
          label: this.$t('transactionMaker'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'type_text',
          label: this.$t('type'),
          sortable: false,
          thStyle: { width: '15%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'detail',
          label: this.$t('details'),
          sortable: false,
          thStyle: { width: '20%' },
          thClass: 'text-center',
        },
        {
          key: 'amount',
          label: this.$t('quantity'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          key: 'balance',
          label: this.$t('remainingBalance'),
          sortable: false,
          thStyle: { width: '10%' },
          thClass: 'text-center',
          tdClass: 'text-center',
          formatter: value => this.Commas(value),
        },
        {
          key: 'actions',
          label: this.$t('manage'),
          sortable: false,
          thStyle: { width: '5%' },
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData(this.isActive)
    this.getCuslist()
  },
  methods: {
    numberFormat(value) {
      this.amount = Math.abs(value)
      return this.amount
    },
    submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          const index = this.typeOp.findIndex(x => x.value === this.type)
          const params = {
            _id: this.id,
            username_cus: this.username_cus,
            type_text: this.typeOp[index].text,
            type: this.type,
            detail: this.detail,
            amount: this.amount,
            prefix: this.prefix,
            prefixnumber: this.prefixnumber,
            type_amount: this.natural_num,
          }
          this.$http
            .post('transactions/storeAndupdate', params)
            .then(() => {
              this.getData(this.isActive)
              this.id = null
              this.username_cus = null
              this.type = null
              this.detail = null
              this.amount = 0
              this.prefix = null
              this.prefixnumber = null
              this.Success()
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
          this.$bvModal.hide('modal-add-new')
        }
      })
    },
    showAddNew() {
      this.id = null
      this.username_cus = null
      this.type = null
      this.detail = null
      this.amount = null
      this.prefix = null
      this.prefixnumber = null
      this.$bvModal.show('modal-add-new')
    },
    showEdit(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.id = data._id
      this.username_cus = {
        username: data.username_cus,
        id: data.uid,
        itemnumber_text: data.itemnumber_text,
      }
      this.type = data.type
      this.detail = data.detail
      this.amount = data.amount
      this.prefix = data.prefix
      this.prefixnumber = data.prefixnumber
      this.natural_num = data.type_amount
      this.$bvModal.show('modal-add-new')
    },
    showPicture(picture) {
      this.showLightbox = true
      const img = { path: this.GetImg('Filewithdraw', picture) }
      this.images.push(img)
      // this.$swal({
      //   imageUrl: picture,
      //   imageWidth: 400,
      //   imageHeight: 600,
      //   imageAlt: 'Custom image',
      //   confirmButtonText: 'ปิด',
      // })
    },
    GetImg(pathfile, filename) {
      const matches = filename.match(
        /^data:image\/([A-Za-z-+\/]+);base64,(.+)$/,
      )
      if (!matches || matches.length !== 3) {
        return `${this.$http.defaults.baseURL}/viewimg?pathfile=${pathfile}/${filename}`
      }
      return filename
    },
    closeBox() {
      this.showLightbox = false
      this.images = []
    },
    async getData() {
      this.show = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        type: this.isActive,
        search: this.search,
      }
      this.$http
        .post('/transactions/list', params)
        .then(response => {
          this.items = response.data.data
          this.natural_num = this.items.type_amount
          this.totalRows = response.data.total
          this.show = false
        })
        .catch(error => console.log(error))
    },
    async getCuslist() {
      this.$http
        .get('/users/cuslist')
        .then(response => {
          this.cuslist = response.data
          console.debug(this.cuslist)
        })
        .catch(error => console.log(error))
    },
    EditType(data) {
      this.$router.push({
        name: 'admin-user-type-edit',
        params: { id: data.id },
      })
    },
    DeleteData(data) {
      this.$bvModal
        .msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
          title: 'ยืนยันการลบข้อมูล',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            const params = {
              id: data.id,
            }
            this.$http.post('groupbyuser/list/delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAmountStyle(amount, typeAmount) {
      if (amount === 21) {
        if (typeAmount) {
          return { color: 'green' }
        }
        return { color: 'red' }
      }
      if ([1, 8, 11, 12, 17, 16].includes(amount)) {
        return { color: 'green' }
      }
      if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20, 22].includes(amount)) {
        return { color: 'red' }
      }

      // if (amount) {
      //   if (amount && [1, 8, 11, 12, 16, 17, 21].includes(amount)) {
      //     return { color: 'green' }
      //   }
      //   if (
      //     amount
      //     && [2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(amount)
      //   ) {
      //     return { color: 'red' }
      //   }
      // }
      // return { color: 'orange' }
    },
    getAmountPrefix(value, typeAmount) {
      if (value === 21) {
        if (typeAmount) {
          return '+'
        }
        return '-'
      }
      if ([1, 8, 11, 12, 17, 16].includes(value)) {
        return '+'
      }
      if ([2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20, 22].includes(value)) {
        return '-'
      }

      // if (value) {
      //   if (value && [1, 8, 11, 12, 17, 16, 21].includes(value)) {
      //     return '+'
      //   }
      //   if (
      //     value
      //     && [2, 3, 4, 5, 6, 7, 13, 15, 18, 10, 9, 20].includes(value)
      //   ) {
      //     return '-'
      //   }
      // }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style scoped>
.b-input-group-append .btn {
  border-radius: 0;
}

.active-btn {
  background-color: #003399 !important;
  border-color: #003399 !important;
  color: white !important;
}

.b-input-group-append .btn-outline-secondary,
.b-input-group-append .btn-outline-secondary:focus,
.b-input-group-append .btn-outline-secondary:active {
  background-color: #f8f9fa;
  border-color: #ced4da;
  color: black;
}

.b-input-group-append .btn-outline-secondary:active,
.b-input-group-append .btn-outline-secondary:focus {
  background-color: #e2e6ea;
}
</style>
